import { graphql } from 'gatsby';
//components
import { HomeHero } from '../components/HomeHero';
import { MobileModal } from '../components/layout/nav/MobileModal';
import { ServicesSlide } from '../components/ServicesSlide';
import { Comfort } from '../components/Comfort';
import { Video } from '../components/Video';
import { DoctorsSlide } from '../components/DoctorsSlide';
import { Technology } from '../components/Technology';
import { Testimonials } from '../components/Testimonials';
import { Seo } from '../components/Seo';
//styles
import { colors, device } from '../components/GlobalStyles';
import styled from '@emotion/styled';
//layout
import { DefaultLayout } from '../layouts/DefaultLayout';

const Contain = styled.div`
    /* @media (min-width: 1800px) {
        padding-top: 3em;
    } */
`;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const Home: Page<any> = ({ data }) => {
    return (
        <main>
            <Seo
                title="Dentist in Florida | Prosthodontist, Periodontist, General Dentist"
                description="Highest quality dentist and dental care service in Tampa Florida since 1983 with best in-class technology. We offer highly personalized experiences"
            />
            <Contain>
                <HomeHero
                    desktopImage={data.desktop.edges[0].node.childImageSharp.gatsbyImageData}
                    mobileImage={data.mobile.edges[0].node.childImageSharp.gatsbyImageData}
                />
                <MobileModal />
                <ServicesSlide />
                <Comfort />
                <Video />
                <DoctorsSlide images={data.doctors.edges} />
                <Technology />
                <Testimonials />
            </Contain>
        </main>
    );
};

export default Home;

export const query = graphql`
    query {
        mobile: allFile(filter: { relativePath: { regex: "/banner-mobile.jpeg/" } }) {
            edges {
                node {
                    id
                    childImageSharp {
                        gatsbyImageData(
                            placeholder: BLURRED
                            quality: 100
                            layout: FIXED
                            formats: [AUTO, WEBP, AVIF]
                        )
                    }
                }
            }
        }
        desktop: allFile(filter: { relativePath: { regex: "/desktop-banner-new.jpg/" } }) {
            edges {
                node {
                    id
                    childImageSharp {
                        gatsbyImageData(
                            layout: FULL_WIDTH
                            height: 100
                            placeholder: BLURRED
                            quality: 100
                            formats: [AUTO, WEBP, AVIF]
                        )
                    }
                }
            }
        }
        doctors: allFile(filter: { relativeDirectory: { eq: "doctorCards" } }) {
            edges {
                node {
                    id
                    childImageSharp {
                        gatsbyImageData(quality: 90, formats: [AUTO, WEBP, JPG])
                    }
                    name
                }
            }
        }
        testimonials: allFile(filter: { relativeDirectory: { eq: "testimonials" } }) {
            edges {
                node {
                    id
                    childImageSharp {
                        gatsbyImageData(quality: 90, formats: [AUTO, WEBP, JPG])
                    }
                    name
                }
            }
        }
    }
`;

Home.Layout = DefaultLayout;
