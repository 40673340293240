import React from 'react';
import styled from '@emotion/styled';
import { device } from './GlobalStyles';
//components
import { ServiceCard } from './cards/ServiceCard';

const services = [
    {
        title: 'General Dentistry',
        description: 'Cosmetic enhancement can have dramatic results on your overall appearance.',
        icon: 'tooth',
        iconAlt: 'pulled tooth',
        url: '/general-dentistry/',
    },
    {
        title: 'Dental Implants',
        description:
            'We believe that dental implants are the ideal solution when it comes to tooth loss.',
        icon: 'screw',
        iconAlt: 'screw implant',
        url: '/dental-implants/',
    },
    {
        title: 'Cosmetic Dentistry',
        description:
            ' Our team can help get the aesthetics of your smile back on track with a makeover.',
        icon: 'smile',
        iconAlt: 'smile showing teeth',
        url: '/cosmetic-dentistry/',
    },
];

const StyledSlide = styled.div`
    margin-bottom: 64.5px;
    h4 {
        padding: 0em 1em;
        padding-top: 5.46em;
        padding-bottom: 2.46em;
    }
    h3 {
        padding-left: 0.5em;
        width: 290px;
        padding-bottom: 1em;
    }
    p {
        padding: 0em 1em;
        padding-bottom: 2em;
    }
    .cards {
        overflow-x: scroll;
        overflow-y: hidden;
        display: flex;
    }
    @media ${device.tablet} {
        margin-bottom: 0px;

        p {
            font-size: 1rem;
            line-height: 28px;
            padding-left: 0em;
            max-width: 600px;
        }
        h3 {
            width: 675px;
        }
    }
    @media ${device.laptop} {
        margin-top: 280px;
        margin-bottom: 0px;
        display: flex;
        text-align: center;
        flex-direction: column;
        align-items: center;
    }
    @media ${device.laptopL} {
    }
    @media ${device.desktop} {
        h3 {
            font-size: 48px;
            line-height: 58px;
        }
        p {
            font-size: 22px;
            line-height: 32px;
        }
    }
`;

export const ServicesSlide: React.FC = () => {
    return (
        <StyledSlide>
            <h4>OUR SERVICES</h4>
            <h3>For Excellent Oral Health and Overall Quality of Life.</h3>
            <p>
                Our services include preventive, restorative, cosmetic, and emergency dentistry. If
                you are in need of any professional dental treatment, you have come to the right
                place!
            </p>
            <div className="cards">
                {services.map(service => (
                    <ServiceCard
                        url={service.url}
                        key={service.title}
                        title={service.title}
                        icon={service.icon}
                        iconAlt={service.iconAlt}
                        description={service.description}
                    />
                ))}
            </div>
        </StyledSlide>
    );
};
