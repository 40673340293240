/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
//plugins
import { Link } from 'gatsby';
import { useState, useRef } from 'react';
import { colors, device } from '../../GlobalStyles';
import styled from '@emotion/styled';
import { StaticImage } from 'gatsby-plugin-image';
import envelopeIcon from '../../../images/icons/message-black.svg';
import phoneIcon from '../../../images/icons/mobile-black.svg';
import mapPinIcon from '../../../images/icons/marker-black.svg';

const StyledMobileModal = styled.div`
    margin-top: 00px;
    background: ${colors.green};
    color: ${colors.white};

    a {
        color: ${colors.white};
    }
    button {
        background: none;
        border: none;
    }
    section:first-of-type {
        display: flex;
        align-self: center;
        padding: 1.5em;
        justify-content: space-between;
        h3 {
            color: ${colors.white};
            max-width: 75%;
          
        }
    }
    section:last-of-type {
        transition: 0.2s ease-in;
        display: flex;
        overflow-x: scroll;
        scroll-behavior: smooth;
        h5 {
            font-size: 1.1em;
            color: ${colors.white};
            opacity: 0.6;
            min-width: 230px;
        }
        .scroll-container {
            width: 200vw;
        }
        section {
            margin-left: -1em;
        }
        > div {
            margin: 0em 1.25em;
        }
        /* //location details */
        > div > div {
            display: flex;
            align-items: center;
            margin: 1.25em 0em;

            img {
                -webkit-filter: grayscale(1) invert(1);
                filter: grayscale(1) invert(1);
                margin-right: 0.75em;
            }
        }
    }
    .arrows {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 73px;
        img {
            width: 21px;
            height: 16px;
        }
        .right-arrow {
            transform: scaleX(-1);
        }
    }
    @media ${device.laptop} {
        display: none;
    }
`;

export const MobileModal: React.FC = () => {
    const myRef = useRef(null);
    const scroll = scrollOffset => {
        myRef.current.scrollLeft += scrollOffset;
    };
    return (
        <StyledMobileModal>
            <section>
                <h3>Conveniently Located</h3>

                <div className="arrows">
                    <p style={{ fontWeight: '600' }}>Scroll Below</p>
                </div>
            </section>
            <section>
                <section ref={myRef} className="scroll-container">
                    <div>
                        <h5>Tampa (Main)</h5>
                        <div className="location-details">
                            <img src={mapPinIcon} alt="map pin" />
                            <a
                                href="https://www.google.com/maps/place/Prosthodontics+and+Implant+Therapy/@28.025022,-82.489328,14z/data=!4m5!3m4!1s0x0:0xb3051970e1eb3aad!8m2!3d28.025022!4d-82.4893281?hl=en-US&shorturl=1"
                                target="_blank"
                                rel="noreferrer"
                            >
                                2814 W Waters Ave
                                <br />
                                Tampa, FL 33614
                            </a>
                        </div>
                        <div className="location-details">
                            <img src={envelopeIcon} alt="envelope" />
                            <a href="mailto:smiletampa@gmail.com">smiletampa@gmail.com</a>
                        </div>
                        <div className="location-details">
                            <img src={phoneIcon} alt="phone" />
                            <a href="tel: (813) 933 6705">(813) 933 6705</a>
                        </div>
                    </div>
                    <div>
                        <h5>Riverview (South)</h5>
                        <div className="location-details">
                            <img src={mapPinIcon} alt="map pin" />
                            <a
                                href="https://www.google.com/maps/place/11948+Boyette+Rd,+Riverview,+FL+33569,+USA/@27.85459,-82.3018767,17z/data=!3m1!4b1!4m5!3m4!1s0x88c2d22a48bb6155:0xe871206708bc6e5c!8m2!3d27.85459!4d-82.299688?hl=en-US"
                                target="_blank"
                                rel="noreferrer"
                            >
                                11948 Boyette Rd
                                <br />
                                Riverview, FL 33569
                            </a>
                        </div>
                        <div>
                            <img src={envelopeIcon} alt="envelope" />
                            <a href="mailto:smileriverview@gmail.com">smileriverview@gmail.com</a>
                        </div>
                        <div>
                            <img src={phoneIcon} alt="phone" />
                            <a href="tel: (813) 373-5525">(813) 373-5525</a>
                        </div>
                    </div>
                    <div>
                        <h5>Land O’ Lakes (North)</h5>
                        <div className="location-details">
                            <img src={mapPinIcon} alt="map pin" />
                            <a
                                href="https://www.google.com/maps/place/20727+Sterlington+Dr,+Land+O'+Lakes,+FL+34638,+USA/@28.1673669,-82.4691579,13z/data=!4m5!3m4!1s0x88c2bbf8f82662dd:0xde7788d4d93c2904!8m2!3d28.1946127!4d-82.4732027?hl=en-US"
                                target="_blank"
                                rel="noreferrer"
                            >
                                20727 Sterlington Dr
                                <br />
                                Land O&apos; Lakes, FL 34638
                            </a>
                        </div>
                        <div>
                            <img src={envelopeIcon} alt="envelope" />
                            <a href="mailto:smilelandolakes@gmail.com">smilelandolakes@gmail.com</a>
                        </div>
                        <div>
                            <img src={phoneIcon} alt="phone" />
                            <a href="tel: (813) 575 9399">(813) 575 9399</a>
                        </div>
                    </div>
                </section>
            </section>
        </StyledMobileModal>
    );
};
