import React, { useState } from 'react';
import { Link } from 'gatsby';
import styled from '@emotion/styled';
import { colors, device } from '../GlobalStyles';
//components
import yellowArrow from '../../images/icons/yellow-arrow-right.svg';
import greenArrow from '../../images/icons/green-arrow-right.svg';
import tooth from '../../images/icons/black-tooth.svg';
import screw from '../../images/icons/black-screw.svg';
import smile from '../../images/icons/black-smile.svg';

interface Props {
    icon: string;
    title: string;
    url: string;
    iconAlt: string;
    description: string;
}
type StyledProps = {
    hovered: boolean;
};

const StyledCard = styled.div<StyledProps>`
    width: 310px;
    height: 326px;
    p {
        padding: 0em;
        font-size: 16px;
        width: 275px;
        color: ${props => (props.hovered ? `${colors.white}` : `${colors.grey}`)};
    }
    button {
        cursor: pointer;
    }
    h5 {
        margin-top: 0.5em;
        margin-bottom: 0em;
        padding: 0.58em 0em;
        color: ${props => (props.hovered ? `${colors.white}` : `${colors.grey}`)};
    }
    cursor: pointer;
    border: 1px solid #d3d3d3;
    background: ${colors.white};
    padding: 2.5em 2em;
    .icon {
        width: 51px;
        height: 51px;
        padding: 8px;
        border-radius: 100%;
        background: ${colors.yellowGradient};
    }

    button:last-of-type {
        color: ${props => (props.hovered ? `${colors.yellow}` : `${colors.green}`)};
        display: flex;
        border: none;
        padding: 0em;
        background: none;
        font-size: 0.8125rem;
        font-weight: 700;
        line-height: 1.375rem;
        letter-spacing: 3px;
        img {
            margin-left: 2em;
            position: relative;
            top: 0.1em;
            stroke: ${colors.green};
        }

        div {
            display: flex;
        }
    }
    p {
        padding-bottom: 1.5em;
    }
    @media ${device.tablet} {
        h5 {
            margin-bottom: 0.5em;
        }
        width: 330px;
        height: 396px;
        text-align: left;
        &:hover {
            color: white;
            background: ${colors.green};
        }
        p {
            padding-bottom: 2.9em;
        }
        h5 {
            margin-top: 1em;
        }
        p {
            padding-bottom: 2.9em;
        }
    }

    @media ${device.desktop} {
        button:last-of-type {
            font-size: 16px;
        }
        margin-bottom: 5em;
        h5 {
            font-size: 32px;
        }

        width: 430px;
        height: 426px;
        p {
            font-size: 18px;
        }
    }
`;

export const ServiceCard: React.FC<Props> = ({ title, icon, url, iconAlt, description }) => {
    const [hovered, setHovered] = useState(false);
    return (
        <>
            <Link to={url}>
                <StyledCard
                    hovered={hovered}
                    onMouseEnter={() => setHovered(true)}
                    onMouseLeave={() => setHovered(false)}
                >
                    {icon === 'tooth' && <img className="icon" src={tooth} alt={`${iconAlt}`} />}
                    {icon === 'screw' && <img className="icon" src={screw} alt={`${iconAlt}`} />}
                    {icon === 'smile' && <img className="icon" src={smile} alt={`${iconAlt}`} />}
                    {/* how would i properly do this? */}
                    {/* <img className="icon" src={`${icon}`} alt={`${icon}`} /> */}

                    <div className="card">
                        <h5>{title}</h5>
                        <p>{description}</p>
                    </div>

                    <button>
                        READ MORE{' '}
                        {hovered ? (
                            <img src={yellowArrow} alt="yellow arrow pointing right" />
                        ) : (
                            <img src={greenArrow} alt="green arrow pointing right" />
                        )}
                    </button>
                </StyledCard>
            </Link>
        </>
    );
};
