import React from 'react';
import { GatsbyImage, getImage, withArtDirection } from 'gatsby-plugin-image';
import { css } from '@emotion/react';
//components
import { HomeHeroPanel } from './HomeHeroPanel';
import styled from '@emotion/styled';
//components
import { YellowButton } from './layout/StyledComponents';
//images
import arrow from '../images/icons/black-arrow-right.svg';
import yellowArrow from '../images/icons/yellow-arrow-right.svg';
import { Link } from 'gatsby';
import envelopeIcon from '../images/icons/envelope-icon.svg';
import phoneIcon from '../images/icons/phone-icon.svg';
import mapPinIcon from '../images/icons/map-pin-icon.svg';
import { colors, device } from './GlobalStyles';
import form from '../form.pdf';

interface Props {
    image: string;
    desktopImage: string;
}

const Container = styled.div`
    background: ${colors.green};
`;

const Button = styled(YellowButton)`
    padding: 2em 3em;
    border-radius: 4px;

    @media ${device.tablet} {
        padding: 1.5em 2em;
    }
    @media (min-width: 1700px) {
        padding-right: 3em;
    }
`;

const Locations = styled.div`
    .patient-button {
        cursor: pointer;
        color: ${colors.yellow};
        display: flex;
        border: none;
        padding: 0em;
        background: none;
        margin-top: 1em;
        font-size: 1.125rem;
        font-weight: 700;
        line-height: 1.375rem;
        letter-spacing: 3px;

        img {
            margin-left: 1em;
            stroke: ${colors.yellow};
        }
        .button-group {
            display: flex;
        }
    }
    word-break: normal;
    position: relative;
    display: none;
    margin-left: auto;
    margin-right: auto;
    max-width: 1350px;
    margin-bottom: -305px;
    padding: 32px 48px;
    border-radius: 8px;
    background: rgba(0, 103, 71, 0.7);
    backdrop-filter: blur(32px);
    color: #e7e7e7;
    > div {
        display: flex;
        justify-content: space-around;
    }
    > h2 {
        text-align: center;
        color: #fff;
        font-size: 3em;
        margin-bottom: 48px;
    }
    @media ${device.laptop} {
        display: block;
    }
    @media ${device.laptopL} {
        .button-group {
            display: flex;
            max-width: 900px;
            margin: 0 auto;
            align-items: center;
            margin-bottom: 2em;

            .second {
                font-size: 16px;
                position: relative;
                bottom: 0.5em;
            }
            .third {
                font-size: 16px;
                position: relative;
                bottom: 0.5em;
                div {
                    display: flex;
                }
                p {
                    font-size: 16px;
                    color: ${colors.yellow};
                }
            }
        }
    }
    @media (max-width: 1382px) {
        top: -32px;
        margin-left: 32px;
        margin-right: 32px;
        > h2 {
            font-size: 2.5em;
        }
    }

    @media (max-width: 1280px) {
        top: 32px;
        padding: 16px;
        > div {
            flex-direction: column;
        }
        > h2 {
            font-size: 2em;
        }
    }
`;

const Location = styled.div`
    padding-left: 8px;
    padding-right: 8px;
    > div {
        display: grid;
        grid-template-columns: 32px 1fr;
        align-items: center;

        > a {
            text-align: left;
            color: #fff !important;
            font-size: 1.1em;
            font-weight: bold;
        }

        padding-bottom: 24px;

        @media (max-width: 1000px) {
            padding-bottom: 8px;
        }
    }

    > h3 {
        font-size: 1.1em;
        color: rgba(231, 231, 231, 0.7);
        margin-bottom: 24px;

        @media (max-width: 1000px) {
            margin-bottom: 8px;
        }
    }

    @media (max-width: 1000px) {
        margin-bottom: 12px;
        padding-left: 0px;
        padding-right: 0px;
    }
`;

export const HomeHero: React.FC<Props> = props => {
    const images = withArtDirection(getImage(props.desktopImage), [
        {
            media: '(max-width: 767px)',
            image: getImage(props.mobileImage),
            alt: 'group photo of the dentist team',
        },
    ]);

    return (
        <Container>
            <GatsbyImage
                loading="eager"
                className="banner"
                css={css`
                    @media (max-width: 767px) {
                        height: 340px;

                        margin-top: 30px;
                    }
                    @media (min-width: 768px) {
                        margin-top: 0px;
                    }
                    @media (min-width: 1280px) {
                    }
                `}
                image={images}
                alt="team of doctors"
            />
            <HomeHeroPanel />
            <Locations>
                <h2>Your Smile is our Mission</h2>
                <div className="button-group">
                    <Button>
                        <Link to="/appointments/">APPOINTMENTS</Link>
                        <Link to="/appointments/">
                            <img src={arrow} alt="black arrow pointing right" />
                        </Link>
                    </Button>

                    <button className="patient-button second">
                        <Link style={{ color: `${colors.yellow}` }} to="patient-resources/forms">
                            PATIENT FORMS
                        </Link>
                        <img src={yellowArrow} alt="yellow arrow pointing right" />
                    </button>

                    <button className="patient-button third">
                        <a download href={form}>
                            <div>
                                <p>REFERRAL FORM</p>
                                <img src={yellowArrow} alt="yellow arrow pointing right" />
                            </div>
                        </a>
                    </button>
                </div>
                <div>
                    <Location>
                        <h3>Tampa (Main)</h3>
                        <div>
                            <img src={mapPinIcon} alt="map pin" />
                            <a
                                href="https://www.google.com/maps/place/Prosthodontics+and+Implant+Therapy/@28.025022,-82.489328,14z/data=!4m5!3m4!1s0x0:0xb3051970e1eb3aad!8m2!3d28.025022!4d-82.4893281?hl=en-US&shorturl=1"
                                target="_blank"
                                rel="noreferrer"
                            >
                                2814 W Waters Ave
                                <br />
                                Tampa, FL 33614
                            </a>
                        </div>
                        <div>
                            <img src={envelopeIcon} alt="envelope" />
                            <a href="mailto:smiletampa@gmail.com">smiletampa@gmail.com</a>
                        </div>
                        <div>
                            <img src={phoneIcon} alt="phone" />
                            <a href="tel: (813) 933 6705">(813) 933 6705</a>
                        </div>
                    </Location>
                    <Location>
                        <h3>Riverview (South)</h3>
                        <div>
                            <img src={mapPinIcon} alt="map pin" />
                            <a
                                href="https://www.google.com/maps/place/11948+Boyette+Rd,+Riverview,+FL+33569,+USA/@27.85459,-82.3018767,17z/data=!3m1!4b1!4m5!3m4!1s0x88c2d22a48bb6155:0xe871206708bc6e5c!8m2!3d27.85459!4d-82.299688?hl=en-US"
                                target="_blank"
                                rel="noreferrer"
                            >
                                11948 Boyette Rd
                                <br />
                                Riverview, FL 33569
                            </a>
                        </div>
                        <div>
                            <img src={envelopeIcon} alt="envelope" />
                            <a href="mailto:smileriverview@gmail.com">smileriverview@gmail.com</a>
                        </div>
                        <div>
                            <img src={phoneIcon} alt="phone" />
                            <a href="tel: (813) 373-5525">(813) 373-5525</a>
                        </div>
                    </Location>
                    <Location>
                        <h3>Land O’ Lakes (North)</h3>
                        <div>
                            <img src={mapPinIcon} alt="map pin" />
                            <a
                                href="https://www.google.com/maps/place/20727+Sterlington+Dr,+Land+O'+Lakes,+FL+34638,+USA/@28.1673669,-82.4691579,13z/data=!4m5!3m4!1s0x88c2bbf8f82662dd:0xde7788d4d93c2904!8m2!3d28.1946127!4d-82.4732027?hl=en-US"
                                target="_blank"
                                rel="noreferrer"
                            >
                                20727 Sterlington Dr
                                <br />
                                Land O&apos; Lakes, FL 34638
                            </a>
                        </div>
                        <div>
                            <img src={envelopeIcon} alt="envelope" />
                            <a href="mailto:smilelandolakes@gmail.com">smilelandolakes@gmail.com</a>
                        </div>
                        <div>
                            <img src={phoneIcon} alt="phone" />
                            <a href="tel: (813) 575 9399">(813) 575 9399</a>
                        </div>
                    </Location>
                </div>
            </Locations>
        </Container>
    );
};
