import React from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { colors, device } from './GlobalStyles';
import { StaticImage } from 'gatsby-plugin-image';
//icons
import playIcon from '../images/icons/play-symbol.svg';

const StyledVideo = styled.section`
    iframe {
        height: 354px;
        width: 100%;
    }
    background: ${colors.green};
    position: relative;
    padding-bottom: 100px;
    h1 {
        line-height: 42px;
    }
    h1 {
        font-weight: 400;
    }
    .text {
        padding: 0em 1em;
        span {
            color: ${colors.yellow};
        }
        h2,
        h1 {
            color: white;
            margin-top: 0.5em;
            width: 350px;
        }
    }
    button {
        display: none;
        position: absolute;
        bottom: 35.5em;
        left: 33%;
        background: ${colors.yellowGradient};
        border: none;

        align-items: center;
        justify-content: center;
        width: 115px;
        height: 115px;
        cursor: pointer;
    }
    @media ${device.tablet} {
        iframe {
            height: 434px;
            width: 52vw;
        }
        padding-bottom: 00px;
        display: flex;
        button {
            display: flex;
            left: 42%;
            width: 90px;
            height: 90px;
            bottom: 40%;
        }
        .text {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 50vw;
            padding: 5em 0em;
            padding-left: 3em;
            span {
                margin-right: 9em;
            }
            h2,
            h1 {
                font-size: 22px;
                max-width: 340px;
            }
        }
    }
    @media ${device.laptop} {
        iframe {
            height: 484px;
            width: 50vw;
        }
        button {
            left: 46.5%;
            width: 115px;
            height: 115px;
        }
        .text {
            margin-left: 0em;
            h2,
            h1 {
                font-size: 42px;
            }
        }
    }
    @media ${device.desktopL} {
        button {
            left: 45vw;
        }
    }
`;

export const Video: React.FC = () => {
    return (
        <StyledVideo>
            <iframe
                src="https://www.youtube.com/embed/Wz8W3ipLfXw"
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
            ></iframe>
            {/* <button>
                <img src={playIcon} alt="symbol for play button" />
            </button> */}
            <div className="text">
                <span>WATCH OUR SHORT VIDEO</span>
                <h1>Dentist in Tampa Florida</h1>
                <h2>Welcome to Prosthodontics & Implant Therapy</h2>
            </div>
        </StyledVideo>
    );
};
