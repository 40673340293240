import React from 'react';
import styled from '@emotion/styled';
import { Link } from 'gatsby';
import { colors, device } from './GlobalStyles';
//components
import { YellowButton } from './layout/StyledComponents';
//images
import arrow from '../images/icons/black-arrow-right.svg';
import yellowArrow from '../images/icons/yellow-arrow-right.svg';
import form from '../form.pdf';

const Panel = styled.section`
    /* bottom: 2rem; */
    background: ${colors.green};
    backdrop-filter: blur(32px);

    z-index: 3;
    .inner-container {
        width: 271px;
    }
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding: 0em 1em;
    button:first-of-type {
        margin-top: 3;
    }
    button:not(:first-of-type) {
        background: none;
        border: none;

        display: flex;
        align-items: center;
        margin-top: 2em;
        padding-bottom: 8px;
        div {
            display: flex;
            p {
                color: ${colors.yellow};
            }
        }
        img {
            margin: 0em 0em 0em 1em;
        }
    }
    button:last-of-type {
        margin-bottom: 16px;
    }
    h2 {
        margin: 32px 0px;
        color: ${colors.white};
        font-size: 32px;
        margin-bottom: -0.5em;
        line-height: 42px;
    }

    @media ${device.tablet} and (max-width: 1023px) {
        h2 {
            font-size: 2.3rem;
            line-height: 52px;
            margin-bottom: -0.5em;
        }
        top: 14.1875rem;
        width: 270px;
    }
    @media ${device.laptop} and (max-width: 1279px) {
        display: none;
        h2 {
            margin-bottom: -0.5em;
            font-size: 2.2em;
            line-height: 43px;
        }
        button:last-of-type {
            margin-left: 0.2em;
        }
        top: 24.1875rem;
        width: 270px;
    }
    @media ${device.laptopL} {
        display: none;
        h2 {
            line-height: 72px;
            margin-bottom: 0em;
            font-size: 3.6rem;
        }
        position: absolute;
        width: 540px;
        height: 284px;
        top: 29rem;
        .button-group {
            display: flex;
            align-items: center;
        }
    }
    @media (min-width: 1600px) {
        h2 {
            font-size: 4.375rem;
        }
        width: 608px;
        height: 334px;
        top: 34rem;
        button:last-of-type {
            img {
                margin-left: 1em;
            }
            min-width: 260px;
            align-items: center;
            font-size: 1.2rem;
        }
    }
`;

const Button = styled(YellowButton)`
    padding: 2em 3em;
    margin-top: 32px;

    @media ${device.tablet} {
        padding: 2em 2em;
    }
    @media (min-width: 1700px) {
        padding-right: 3em;
    }
`;

export const HomeHeroPanel: React.FC = () => {
    return (
        <Panel>
            <div className="inner-container">
                <h2>Your Smile is our Mission.</h2>
                <div className="button-group">
                    <Button>
                        <Link to="/appointments/">APPOINTMENTS</Link>
                        <Link to="/appointments/">
                            <img src={arrow} alt="black arrow pointing right" />
                        </Link>
                    </Button>

                    <button className="patient-button second">
                        <Link style={{ color: `${colors.yellow}` }} to="patient-resources/forms">
                            PATIENT FORMS
                        </Link>
                        <img src={yellowArrow} alt="yellow arrow pointing right" />
                    </button>
                    <button className="patient-button third">
                        <a download href={form}>
                            <div>
                                <p>REFERRAL FORM</p>
                                <img src={yellowArrow} alt="yellow arrow pointing right" />
                            </div>
                        </a>
                    </button>
                </div>
            </div>
        </Panel>
    );
};
