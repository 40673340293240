import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/css';
import { colors, device } from '../GlobalStyles';
import { GatsbyImage } from 'gatsby-plugin-image';
import { Link } from 'gatsby';

const StyledCard = styled.div`
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    filter: drop-shadow(0px 16px 16px rgba(0, 0, 0, 0.1));
    margin-bottom: 64px;
    min-width: 311px;
    max-width: 311px;
    margin-right: 1em;
    &:hover {
        box-shadow: rgba(100, 100, 111, 0.5) 0px 7px 29px 0px;
    }

    p {
        margin-left: 15px;
        font-size: 21px;
        font-weight: 700;
    }
    p:last-of-type {
        font-size: 16px;
        padding-bottom: 1em;
        font-weight: 400;
        color: ${colors.green};
    }
    @media ${device.tablet} {
        margin-bottom: 80px;
    }
`;

type Props = {
    name: string;
    title: string;
    imageName: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    images: any;
    path: string;
    //do i now define the images object ? confused here
};

export const DoctorCard: React.FC<Props> = ({ images, path, imageName, name, title }) => {
    // here we filter through the nodes from the gatsby graphql query
    const cardImage = images.filter(
        // this line is confusing but i think clears up how to define types that are objects?
        (image: { node: { name: string } }) => image.node.name === imageName
    );
    return (
        <StyledCard>
            <Link to={`/about-us/${path}`}>
                <GatsbyImage
                    alt={imageName}
                    image={cardImage[0].node.childImageSharp.gatsbyImageData}
                    className={css({
                        margin: '1em 0.5em 2em',
                        height: '350px',
                    })}
                />
                <p>{name}</p>
                <p>{title}</p>
            </Link>
        </StyledCard>
    );
};
