import React from 'react';
import { css } from '@emotion/css';
import styled from '@emotion/styled';
import { Link } from 'gatsby';
import { colors, device } from './GlobalStyles';
import { StaticImage } from 'gatsby-plugin-image';
//components
import { YellowButton } from './layout/StyledComponents';
import arrow from '../images/icons/black-arrow-right.svg';
//images
import greenArrow from '../images/icons/green-arrow-right.svg';

const ComfortStyled = styled.section`
    padding: 0em 1em;

    h1 {
        font-size: 22px;
    }
    .div {
        max-height: 280px;
    }
    div {
        position: relative;
    }
    h4 {
        padding-bottom: 2.46em;
    }
    h2 {
        font-size: 3rem;
        margin-bottom: 32px;
    }
    .text {
        position: relative;
    }
    p {
        font-size: 18px;
        line-height: 30px;
        margin-bottom: 100px;
    }
    .big-square {
        display: none;
    }
    .second-button {
        color: ${colors.green};
        display: flex;
        border: none;
        padding: 0em;
        cursor: pointer;
        margin: 2.5em 0em;
        background: none;
        font-size: 0.8125rem;
        font-weight: 700;
        line-height: 1.375rem;
        letter-spacing: 3px;
        &:hover {
            text-decoration: underline;
            color: ${colors.green};
        }
        img {
            position: relative;
            top: 0.1em;
            margin-left: 1em;
            stroke: ${colors.green};
        }
        div {
            display: flex;
        }
    }

    .small-square {
        background: ${colors.yellowGradient};
        width: 100.66px;
        position: absolute;
        top: 9em;
        padding-left: 0.4em;
        left: 65vw;
        height: 95px;
        border-radius: 4px;
        div {
            color: white;
            font-weight: 700;
            font-size: 32px;
            &:first-of-type {
                color: black;
                margin-top: 0.7em;
                margin-bottom: 1em;
                font-size: 18px;
            }
        }
    }

    @media (min-width: 400px) and (max-width: 550px) {
        .small-square {
            top: 3em;
        }
        .buttons {
            margin-top: 1em;
        }
    }
    @media ${device.tablet} {
        h1 {
            margin-bottom: -2em;
        }
        display: flex;
        margin-left: 20px;
        flex-direction: column;
        .small-square {
            top: 17em;
            margin-left: 5.5em;
        }
        p {
            width: 650px;
        }
        button {
            margin-top: -3em;
            align-self: flex-start;
        }
        h4 {
            margin-top: 4.46em;
        }
    }
    @media ${device.laptop} and (max-width: 1279px) {
        padding-left: 30px;
        .small-square {
            top: 8em;
            padding-left: 1em;
            padding-top: 2em;
            width: 180px;
            height: 180px;
            div {
                font-size: 42px;
                &:first-of-type {
                    font-size: 24px;
                }
            }
        }
        .div {
            min-height: 720px;
        }
    }

    @media (min-width: 1000px) {
        position: relative;
        padding-bottom: 5em;
        margin-top: 20px;
        padding-left: 4em;
        display: flex;
        align-items: baseline;
        margin-bottom: -3em;
        .small-square {
            display: none;
        }
        .div {
            min-height: 00px;
            max-height: 00px;
        }
        h2 {
            font-size: 48px;
            width: 450px;
            line-height: 58px;
        }
        p,
        .text {
            width: 430px;
        }
        .big-square {
            display: none;
            background: ${colors.yellowGradient};
            width: 291px;
            position: absolute;

            padding-top: 4em;
            padding-left: 1.3em;
            margin-left: 470px;
            height: 274px;
            border-radius: 4px;
            p {
                color: white;
                font-weight: 700;
                font-size: 96px;
                &:first-of-type {
                    color: black;
                    margin-bottom: 2em;
                    font-size: 28px;
                }
            }
        }
        .buttons {
            display: flex;
            margin-bottom: 100px;
        }
        .second-button {
            position: relative;
            bottom: 3.5em;
            left: 2em;
        }
    }

    @media ${device.laptopL} {
        margin-left: 5em;

        .big-square {
            display: block;
            top: 380px;
        }
        display: flex;
    }
    @media (min-width: 1580px) {
        margin-left: 6em;
    }
    @media (min-width: 1780px) {
        margin-left: 12em;
    }
    @media (min-width: 1980px) {
        margin-left: 18em;
    }
`;

const Button = styled(YellowButton)`
    padding: 2em 3em;
    margin-top: 2em;
    @media (min-width: 1600px) {
        padding-right: 3em;
    }
`;

export const Comfort: React.FC = () => {
    return (
        <ComfortStyled>
            <div className="text">
                <h4>AT THE FRONT LINES OF DENTISTRY</h4>
                <h2>Providing optimal comfort</h2>
                <p>
                    Our philosophy is simply to care for you as our patient with the highest quality
                    treatment and respect you deserve. From the first phone call you make to our
                    office, we begin creating a highly personalized experience for you.
                </p>
                <div className="small-square">
                    <div>SINCE</div>
                    <div>1983</div>
                </div>
            </div>

            <div className="div">
                <StaticImage
                    src="../images/doctorCards/dr-nima-card-new.jpeg"
                    alt="full frontal pic of dr. nima"
                    className={css`
                        width: 167px;
                        position: relative;
                        bottom: 60px;
                        @media (min-width: 768px) {
                            width: 181px;
                            bottom: 60px;
                            margin-left: 290px;
                            margin-right: 10px;
                        }

                        @media (min-width: 1000px) {
                            width: 311px;
                            bottom: 450px;
                            margin-left: 520px;
                            margin-right: 10px;
                            height: 100%;
                        }
                        @media (min-width: 1500px) {
                            width: 311px;
                            bottom: 450px;
                            margin-left: 610px;
                            margin-right: 10px;
                            height: 100%;
                        }
                    `}
                />
                <StaticImage
                    src="../images/doctorCards/dr-reza-card-new.jpeg"
                    alt="full frontal pic of dr. reza"
                    className={css`
                        width: 167px;
                        position: relative;
                        left: 10px;
                        margin-bottom: 80px;
                        @media (min-width: 769px) and (max-width: 1279px) {
                            display: none;
                        }
                        @media (min-width: 1280px) {
                            width: 311px;
                            height: 100%;
                            bottom: 360px;
                        }
                    `}
                />
            </div>
            <div className="big-square">
                <p>SINCE</p>
                <p>1983</p>
            </div>
            <div className="buttons">
                <Button>
                    <Link to="/appointments/">APPOINTMENTS</Link>
                    <Link to="/appointments/">
                        <img src={arrow} alt="black arrow pointing right" />
                    </Link>
                </Button>
                <Link to="/patient-resources/forms">
                    <button className="second-button">
                        PATIENT FORMS
                        <img src={greenArrow} alt="green arrow pointing right" />
                    </button>
                </Link>
            </div>
        </ComfortStyled>
    );
};
